import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { cardIcon } from "../../data";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="bg-[#F9F9F9] sm:mx-0 mx-6 ">
      <Slider {...settings}>
        <div>
          <div className="sm:col-span-4  col-span-6 pl-7  py-10">
            <img src="Group 1202.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              We use the{" "}
              <span className="font-bold">
                highest standard of encryption, AES 256-bit
              </span>
              , to protect your data both in transit and at rest.
            </p>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4  col-span-6 px-5 py-10">
            <img src="Group 1218.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              You can generate{" "}
              <span className="font-bold">your own private key</span> or have
              one generated during deployment.
            </p>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4  col-span-6  px-5 py-10">
            <img src="Group 1219.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              Our ISO-certified data centers are worldwide, with additional
              certifications specific to each area.
            </p>
          </div>
        </div>
        <div>
          <div className="sm:col-span-4  col-span-6  px-5 py-10">
            <img src="Group 1202.png" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              We just backup what you need, so you{" "}
              <span className="font-bold">won't waste time and resources</span>
              on temporary or transient data.
            </p>
          </div>
        </div>
        {cardIcon.map((item, index) => (
          <div className="sm:col-span-4  col-span-6  px-5 py-10">
            <img src={item.icon} alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              {item.paragraph}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
