import React from "react";
import Carousel from "../../components/carusol";

const Pricing = () => {
  return (
    <React.Fragment>
      <div className="max-w-[1150px] sm:py-[60px] py-[30] m-auto ">
        <div className="grid grid-cols-12 px-5 ">
          <div className="lg:col-span-7 md:col-span-12 order-last md:order-last lg:order-first col-span-12">
            <h1 className="sm:text-[48px] text-[25px] font-[700] font-sans">
              Pricing{" "}
            </h1>
            <p className="sm:text-[24px] text-[16px] sm:mt-12 mt-7 font-[400]">
              Whether you’re a small company, freelancer or end user, DataSafe
              has got you covered. We have a wide range of pricing plans to make
              sure you get the best value for money. Here's a breakdown of our
              plans:
            </p>
          </div>
          <div className="lg:col-span-5 md:col-span-12 col-span-12  flex lg:justify-end justify-center">
            <img
              src="/Group 1203.png"
              alt="image tag"
              width={300}
              className="w-[50] h-50 "
            />
          </div>
        </div>
        <div className="grid grid-cols-12 mx-5 ">
          <div className="lg:col-span-4 md:col-span-6 col-span-12 p-7 lg:border-dashed  lg:border-r-2 lg:border-b-2">
            <img src="Group 1202.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              We start at <span className="font-bold">100GB for just 15€</span>{" "}
              and every extra GB is only 0.16€.
            </p>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-12  p-7 lg:border-dashed  lg:border-r-2 lg:border-b-2">
            <img src="Group 1198.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              Our prices include the servers so you won’t need to worry about
              overly complex integrations.
            </p>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-12 p-7 lg:border-dashed   lg:border-b-2">
            <img src="Group 1199.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              And the best part? It’s one of the easiest and most user friendly
              services in the market.
            </p>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-12 p-7 lg:border-dashed  lg:border-r-2 ">
            <img src="Group 1197.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              We have different GB limits – 100GB, 250GB, 500GB, 750GB, 1000GB
              and unlimited – so you won’t have to worry about overspending. 
            </p>
          </div>
          <div className="lg:col-span-4 md:col-span-6 col-span-12 p-7 lg:border-dashed  lg:border-r-2  ">
            <img src="Group 1201.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              For extra peace of mind, we also offer a fully integrated service
              to{" "}
              <span className="font-bold">
                protect your Microsoft 365 account
              </span>
              , for just €3.49.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-20">
          <div className="sm:col-span-6 md:col-span-6 col-span-12">
            <img
              src="/bg-img.png"
              alt="imges"
              style={{
                height: "100%",
                width: "100%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            />
          </div>
          <div className="sm:col-span-6 md:col-span-6 col-span-12  bg-[#05164D]">
            <div
              style={{
                backgroundImage: "url(/mask2.png)",
                // height: "473px",
                // background: "red",
                width: "100%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="py-[30px] px-[30px]">
                <div className="max-w-[500px] w-[100%]">
                  <p className="sm:text-[48px] text-[29px] font-bold text-white">
                    DataSafe Cloud backup
                  </p>
                </div>

                <p
                  className="sm:text-[24px] text-[12px] font-[400] text-white"
                  style={{ lineHeight: "36px" }}
                >
                  We know how important it is to keep your data secure. That’s
                  why DataSafe comes with top-of-the-line security measures.
                  Here are some of the features that make us the best cloud
                  backup solution:
                </p>
              </div>
            </div>
          </div>
        </div>

        <Carousel />

        {/* <Carousel /> */}

        <div className="grid grid-cols-12 mx-5 ">
          <div className=" lg:col-span-7 md:col-span-12 sm:col-span-12  col-span-12  order-last md:order-last lg:order-first ">
            <h1 className="sm:text-[48px] text-[29px] font-[700] font-sans mt-20">
              Microsoft 365 data protection
            </h1>
            <p className="sm:text-[24px] text-[16px] mt-5 font-[400]">
              If you’re a Microsoft 365 user, you know how difficult it can be
              to keep track of all your data. That’s why DataSafe has got you
              covered with our Microsoft 365 data protection service. Here’s
              what you'll get: 
            </p>
          </div>
          <div className=" lg:col-span-5  md:col-span-12  sm:col-span-12 col-span-12    flex lg:justify-end justify-center  mt-10  sm:mt-20">
            <img
              src="/Group 1227.svg"
              alt="image tag"
              width={300}
              className="w-[50] h-50"
            />
          </div>
        </div>

        <div className="grid grid-cols-12 space-x-4 mx-5 my-20 ">
          <div className="lg:col-span-3 md:col-span-6 col-span-12  p-2 sm:border-dashed  sm:border-r-2 ">
            <img src="Group 1197 (1).svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              We offer recovery of deleted items to help you keep control of{" "}
              <br />
              your customers’ data in Microsoft 365.
            </p>
          </div>
          <div className="lg:col-span-3 md:col-span-6 col-span-12 p-2 sm:border-dashed  sm:border-r-2 ">
            <img src="Group 1197 (2).svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              You’ll be able to manage your Microsoft 365 backups from the same
              dashboard as server and workstation backups.
            </p>
          </div>
          <div className="lg:col-span-3 md:col-span-6 col-span-12 p-2  border-dashed  sm:border-r-2 ">
            <img src="Group 1225.svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              Enjoy up to seven years of data retention, so you can meet your
              regulatory requirements.
            </p>
          </div>
          <div className="lg:col-span-3 md:col-span-6 col-span-12 p-2 ">
            <img src="Group 1197 (3).svg" alt="icon" />
            <p className="sm:text-[24px] text-[16px] mt-12 font-[400]">
              All your storage needs are taken care of – we provide global
              private cloud storage.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
