import React from "react";
import {
  termsPara,
  termstwo,
  OBLIGATIONS,
  RESPONSIBILITIES,
  TERMINATION,
  MODIFICATIONS,
  Privacy,
  SERVICE,
  INTELLECTUAL,
  CONFIDENTIALITY,
} from "../../data";
const Policy = () => {
  return (
    <React.Fragment>
      <div className="max-w-[1112px] w-[100%] m-auto">
        <div className="px-5">
          <p className="sm:text-[24px] text-[16px]   font-[400] mt-14">
            Confidentiality and security are core values of Data-Safe and,
            consequently, we are committed to ensuring the privacy of the user
            at all times and not to collect unnecessary information. Below, we
            provide you with all the necessary information about our Privacy
            Policy in relation to the personal data we collect, explaining:
          </p>
          <ul class="list-disc list-inside   my-10 sm:text-[24px] text-[16px]">
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">
                Who is responsible for the processing of your data.
              </span>
            </li>
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">
                For what purposes we collect the data we request from you.
              </span>
            </li>
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">
                What is the legitimacy for its treatment.
              </span>
            </li>
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">How long we keep them.</span>
            </li>
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">
                To which recipients your data is communicated.
              </span>
            </li>
            <li class="text-[#FFAD00]  font-[400]">
              <span className=" text-black">What are your rights.</span>
            </li>
          </ul>
          <p className=" sm:text-[24px] text-[18px]  font-bold mt-14 uppercase">
            1. Responsible:
          </p>

          <ol class=" pt-7 sm:text-[24px] text-[18px] ">
            <li className=" py-2 font-[400]">
              TRAFFIX MARKETING ONLINE S.C.P. (J-67363887)
            </li>
            <li className=" py-2 font-[400]">
              50 Josep Tarradellas Avenue
            </li>
            <li className=" py-2 font-[400]">08029 Barcelona, Spain</li>
            <li className=" py-2 font-[400]">datasafe@traffix.es</li>
          </ol>
          <p className="text-[24px]  font-bold mt-4 uppercase">
            2. Purposes, legitimacy and conservation of the processing of the
            data sent through:
          </p>

          <div className="my-5 ">
            <p className="sm:text-[24px] text-[18px] font-[400]">
              To use the Service the user must create a profile at the time of
              requesting activation of the service. The information collected by
              the Service will vary accordingly. Personal and non-personal
              information collected by the Service will come to us in three
              ways: 1) information collected automatically upon consent, 2)
              information provided voluntarily by the user, and 3) third party
              consenting location.
            </p>
          </div>
          <ol class=" ">
            <di className="flex justify-start">
              <p className="sm:text-[24px] text-[18px] pr-4 font-[400]">1.</p>
              <li className=" font-[400]">
                Automatically collected with your consent:
              </li>
            </di>
          </ol>
          <p className="sm:text-[24px] text-[18px]  my-4 flex font-[400] mt-2">
            2.
            <ul class="list-disc list-inside  sm:ml-5 ">
              <li class="text-[#FFAD00]   font-[400]">
                <span className=" text-black  ">
                  Collected through cookies or similar mechanisms stored on your
                  device, always with your consent.
                </span>
              </li>
              <li class="text-[#FFAD00]  py-3  font-[400]">
                <span className=" text-black  ">
                  The IP from which the connection is made, the type of device
                  used and its characteristics, the operating system version,
                  the type of browser, the language, the date, the country, the
                  time of the request, the referrer URL or the mobile network
                  used (among others).
                </span>
              </li>
              <li class="text-[#FFAD00]  py-3 font-[400]">
                <span className=" text-black">
                  Service usage data and possible errors detected during its
                  use.
                </span>
              </li>
              <li class="text-[#FFAD00]  py-3 font-[400]">
                <span className=" text-black">
                  Data about your physical location using different
                  technologies: GPS signals sent by a mobile device or
                  information about nearby Wi-Fi access points or cell phone
                  antennas. In any case, this data is always collected in
                  accordance with your device's privacy settings.
                </span>
              </li>
              <li class="text-[#FFAD00]  py-3 font-[400]">
                <span className=" text-black">
                  In addition, Data-Safe uses Google Analytics, an analytical
                  service provided by Google, Inc. domiciled in the United
                  States with headquarters at 1600 Amphitheatre Parkway,
                  Mountain View, California 94043. For the provision of these
                  services, they use cookies that collect information, including
                  the IP address of the user, which will be transmitted,
                  processed and stored by Google on the terms set out in the web
                  www.google.com. Including the possible transmission of such
                  information to third parties for reasons of legal requirement
                  or where such third parties process the information on behalf
                  of Google.
                </span>
              </li>
              <li class="text-[#FFAD00]  py-3 font-[400]">
                <span className=" text-black">
                  In any case, you can disable Google Analytics cookies from
                  Link.
                </span>
              </li>
            </ul>
          </p>

          <p className="sm:text-[24px] text-[18px]  font-[400] mt-2">
            3.
            <span className="ml-5 ">
              See our
              <span className="underline text-[#FFAD00]"> Cookie Policy</span>{" "}
              for more information
            </span>
          </p>
          <p className="sm:text-[24px] text-[18px]  font-[400] mt-2">
            4.
            <span className="ml-5">
              The one provided voluntarily by the user:
            </span>
          </p>
          <p className="sm:text-[24px] text-[18px]  font-[400] mt-2">
            <span className="sm:ml-11 ml:5 ">SENDING E-MAILS.</span>
          </p>
          <div className="sm:ml-11 ml:5 ">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Purpose: </span> Provision of the
              Service and management of the contractual relationship, which
              includes the management of the subscription itself, your Receipts
              and your interaction with any of our internal departments, such as
              customer service, technical service or for the attention of
              claims, changes in the subscription or incidents. We will also
              process your contact data in order to enable Customer access to
              your private area, which you can access through the web
            </p>
          </div>
          <div className="sm:ml-11 ml:3 ">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Legitimation </span> The consent of
              the user when requesting information through the email address or
              sending us your data and CV to participate in our selection
              processes.
            </p>
          </div>
          <div className="sm:ml-11 ml:3 ">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Conservation:</span> Once your
              request is answered by e-mail, if it has not generated a new
              treatment. In the case of receiving your CV, your data may be kept
              for a maximum of one year for future selection processes.
            </p>
          </div>
          <p className="sm:text-[24px] text-[18px]  font-[400] mt-2">
            5.
            <span className="sm:ml-3">
              REGISTRATION/SUBSCRIPTION FORM TO OUR SERVICES.
            </span>
          </p>
          <div className="sm:ml-11 ">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Purpose: </span> Provision of the
              Service and management of the contractual relationship, which
              includes the management of the subscription itself, your Receipts
              and your interaction with any of our internal departments, such as
              customer service, technical service or for the attention of
              claims, changes in the subscription or incidents. We will also
              process your contact data in order to enable Customer access to
              your private area, which you can access through the web
            </p>
          </div>
          <div className="sm:ml-11">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Legitimation </span> Execution of
              the contractual relationship.
            </p>
          </div>
          <div className="sm:ml-11">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              <span className="font-bold  ">Conservation: </span> As long as the
              contractual relationship remains in force and after that we will
              keep them blocked during the conservation periods required by law
              to meet any liabilities. * In any case, when the user contracts
              the services offered, he/she accepts that he/she has obtained the
              number/s to be located in a lawful manner and with the prior
              consent of the owner.
            </p>
          </div>
          <div className="sm:ml-11 ml-5 ">
            <p className="sm:text-[24px] text-[18px] font-[400] my-4">
              The third party that consents to the location:
            </p>
          </div>

          <ol class=" sm:text-[24px] text-[18px]">
            <p className=" pr-2 font-[400] mt-5">
              The third party who consents to the location, will provide
              automated information about your device as indicated in point 1 of
              this section, your phone number and your specific and specific
              position for the time at which you have consented to make it
              known.
            </p>
            <p className=" pr-2 font-[400] mt-5">
              In no case will the location go beyond the specific time at which
              it has been accepted.
            </p>
            <p className=" font-[400] mt-5">
              The third party who does not consent to the location, will yield
              to Data-Safe automated information about your device as indicated
              in point 1 of this paragraph, your phone number and the name with
              which it would have been associated.
            </p>
          </ol>
          <p className="  font-bold mt-2">
            Obligation to provide us with your personal data and consequences of
            not doing so.
          </p>

          <ol class="sm:text-[24px] text-[18px]">
            <p className=" pr-2 font-[400] mt-5">
              The provision of personal data requires a minimum age of 14 years,
              or if applicable, to be of legal age to contract.
            </p>
            <p className=" font-[400] mt-5">
              The personal data requested are necessary to manage your requests
              and/or provide you with the services you may contract, therefore,
              if you do not provide them, we will not be able to attend you
              correctly or provide you with the service you have requested.
            </p>
          </ol>
          <p className="   font-bold mt-2 uppercase">
            3. Recipients of your data
          </p>

          <ol class="mt-2  sm:text-[24px] text-[18px]">
            <di className="flex justify-start py-1">
              <li className=" font-[400]">
                Your data are confidential and will not be disclosed to third
                parties, unless there is a legal
                <br /> obligation or when the provision of the service implies
                the need for a contractual relationship with service providers
                in charge of the processing, who will not process your data for
                their own
                <br /> purposes.
              </li>
            </di>
          </ol>

          <p className=" sm:text-[24px] text-[18px]  font-bold mt-2 uppercase">
            4. Rights in relation to your personal data
          </p>

          <div className=" sm:text-[24px] text-[18px]">
            <p className=" font-[400]">
              Any person may withdraw their consent at any time, when it has
              been given for the processing of their data. In any case, the
              withdrawal of this consent does not condition the execution of the
              <br />
              subscription contract or the relationships previously generated.
            </p>
            <p className=" font-[400] my-8">
              You may also exercise the following rights:
            </p>
            <p className=" font-[400]">
              Request access to your personal data or its rectification when it
              is inaccurate.
              <br />
              Request their deletion when, among other reasons, the data are no
              longer necessary for the
              <br /> purposes for which they were collected.
              <br />
              Request a restriction of your treatment in certain circumstances.
              <br />
              Request opposition to the processing of your data for reasons
              related to your particular situation. Request data portability in
              the cases provided for in the regulations.
              <br />
              Other rights recognized in the applicable regulations.
            </p>
            <p className=" font-[400] mt-14">
              Where and how to request your Rights: By writing to the
              responsible party at its postal or e-mail address (indicated in
              section A), indicating the reference "Personal Data", specifying
              the right you wish to exercise and with respect to which personal
              data.
            </p>
            <p className=" font-[400]  mt-10">
              In case of disagreement with the company regarding the processing
              of your data, you can file a complaint with the Data Protection
              Authority (www.agpd.es).
            </p>
          </div>
          <p className="sm:text-[24px] text-[18px] font-bold  mt-2 uppercase">5. Cookies</p>

          <div className="sm:text-[24px] text-[18px] ">
            <p className=" font-[400]">
              This website uses technical, personalization, analysis and
              advertising cookies, own and third party cookies that process
              connection and/or device data, as well as browsing habits for
              statistical and advertising purposes.
            </p>
            <p className=" font-[400] mt-8">
              Therefore, when accessing our website, in compliance with Article
              22 of the Law on Information Society Services, when dealing with
              the aforementioned cookies, we have requested your consent for
              their use and provided information about them.
            </p>
            <p className=" font-[400] mt-5">
              See our <span className="underline">Cookie Policy</span> for more
              information.
            </p>
          </div>
          <p className="sm:text-[24px] text-[18px]  font-bold mt-2 uppercase">
            6. Security of your personal data
          </p>

          <div className="my-5 sm:text-[24px] text-[18px]">
            <p className=" font-[400]">
              In order to safeguard the security of your personal data, we
              inform you that we have adopted all the necessary technical and
              organizational measures to ensure the security of the personal
              data provided from alteration, loss, and unauthorized processing
              or access.
            </p>
          </div>
          <p className="sm:text-[24px] text-[18px]  font-bold mt-2 uppercase">
            7. Updating your data
          </p>

          <div className="sm:text-[24px] text-[18px]">
            <p className=" font-[400]">
              In order for us to keep your personal data updated, it is
              important that you inform us whenever there has been any change in
              them, otherwise, we are not responsible for the veracity of them.
            </p>
            <p className=" font-[400] mt-5">
              We are not responsible for the privacy policy regarding personal
              data that you may provide to third parties through links available
              on our website.
            </p>
            <p className=" font-[400] my-5">
              This Privacy Policy may be modified to adapt them to changes that
              occur on our website, as well as legislative or jurisprudential
              changes on personal data that appear, so it requires reading, each
              time you provide us with your data through this website.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Policy;
