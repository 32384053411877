import React from "react";
import { useNavigate } from "react-router-dom";

const TopHeader = ({ text }) => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="bg-[#05164d] text-white">
        <div
          style={{
            backgroundImage: "url(/pricebg.png)",
            height: "100%",
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="py-[98px]">
            <div className=" flex justify-center  items-center">
              <img
                onClick={() => navigate("/")}
                src="/Group (3).svg"
                alt="logo"
                className="w-[200px] h-auto cursor-pointer"
              />
            </div>

            <h1
              className="font-[700] text-center sm:[48px] text-[34.57px] mt-4 px-4"
              style={{ lineHeight: "38px" }}
            >
              {text}
            </h1>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopHeader;
