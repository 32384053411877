import React from "react";
import {
  termsPara,
  termstwo,
  OBLIGATIONS,
  RESPONSIBILITIES,
  TERMINATION,
  MODIFICATIONS,
  SERVICE,
  INTELLECTUAL,
  CONFIDENTIALITY,
} from "../../data";

const Conditions = () => {
  return (
    <React.Fragment>
      <div className="max-w-[1112px] w-[100%] m-auto sm:px-20 px-7">
        <div>
          <p className=" sm:text-[22px] text-[16px] font-[400] mt-14">
            This document (hereinafter, the "Agreement") constitutes a legally
            binding agreement between you and Traffix Marketing online SCP governing your use of
            the REMOTE BACKUP SERVICE, called Traffix Marketing online SCP
            BACKUP ONLINE, on the basis of the following:
          </p>
          <p className="sm:text-[24px] text-[20px] font-bold sm:mt-14 mt-3">A. ANTECEDENTS:</p>

          <ol class="list-[upper-roman]  justify-center sm:sm:p-7 p-4">
            {termsPara.map((item, index) => (
              <li className="sm:text-[24px]  text-[16px]  font-[400]">{item.paragraph}</li>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            1. PURPOSE OF THE CONTRACT.
          </p>

          <div className="sm:p-7 p-1 ">
            <p className="sm:text-[24px]  text-[16px] font-[400]">
              The parties agree to this contract for the PROVISION OF
              WAREHOUSING SERVICES, whereby Traffix Marketing online SCP shall
              provide the client with such services, subject to the rights and
              obligations resulting from this contract.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            2. ENTRY INTO FORCE AND DURATION OF THE SERVICES.
          </p>

          <div className="sm:p-7 p-1">
            <p className="sm:text-[24px]  text-[16px] font-[400]">
              The Contract shall enter into force on the same day of its
              formalization.
            </p>
          </div>

          <p className="sm:text-[24px] text-[17px] font-bold mt-2">3. PRICE OF THE SERVICE.</p>

          <div className="sm:p-7 p-1">
            <p className="sm:text-[24px]  text-[16px] font-[400]">
              Traffix Marketing online SCP will charge the Customer the rates
              indicated in this Agreement and the other rates in force at any
              given time, available at www.datasafe.net, which the Customer
              accepts when contracting the Service. The current rates, prices,
              discounts and applicable taxes are broken down according to the
              Services and associated facilities. Any change in the rates will
              be communicated to the Client at least 30 days prior to the
              effective date. The modification of the tariffs will entitle the
              Client to terminate the Contract. The Client is obliged to pay the
              Value Added Tax corresponding to each item.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            4. INVOICING AND PAYMENT.
          </p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              Traffix Marketing online SCP will start invoicing the Client from
              the date of commencement of the Services. The invoices submitted
              to the Customer will contain properly differentiated price
              concepts that are priced for the Services and facilities provided.
              Traffix Marketing online SCP shall make the invoices available to
              the Client in electronic format, in accordance with the applicable
              regulations. The Customer shall pay the amounts due for the
              provision of the Services requested, by direct debit. Invoices not
              paid on the due dates will accrue interest for late payment and
              compensation for collection costs under the terms provided in Law
              15/2010 of July 5, 2010, amending Law 3/2004 of December 29, 2004,
              which establishes measures to combat late payment in commercial
              transactions, without prejudice to the consequences that may arise
              from non-compliance, which may involve, including, the suspension
              or cancellation of the Service, prior communication of the latter
              within a period of not less than 7 days, as well as a commission
              in the amount of 4% of the amount owed plus 5 ¤ (VAT included), as
              a collection management fee (the same default interest will be
              applied in the event that, as a result of a claim, the Customer is
              entitled to a refund of any amount by Traffix Marketing online
              SCP). The parties agree that in the event that Traffix Marketing
              online SCP proceeds to claim an invoice due through legal
              proceedings, the debtor Customer agrees to pay a penalty of 150€,
              in addition to the amounts claimed and the procedural costs,
              generating an invoice that will claim such penalty. The parties
              expressly acknowledge that the computer records of Traffix
              Marketing online SCP and their reproduction constitute valid and
              sufficient proof of the services requested and actually consumed
              by the Customer.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">5. SECURITY DEPOSITS.</p>

          <div className="">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              Traffix Marketing online SCP may require the constitution of
              security deposits at the time of contracting the Services or
              during the term of the Agreement when:
            </p>
          </div>

          <ol class="">
            {termstwo.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px]  text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            6. OBLIGATIONS OF Traffix Marketing online SCP.
          </p>
          <div className="">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              Within the framework of the present contract, Traffix Marketing
              online SCP is obliged to:
            </p>
          </div>
          <ol class="">
            {OBLIGATIONS.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            7. OBLIGATIONS AND RESPONSIBILITIES OF THE CLIENT - CONSEQUENCES IN
            THE EVENT OF NON-COMPLIANCE - CONSEQUENCES IN THE EVENT OF
            NON-COMPLIANCE
          </p>

          <ol class="mt-2">
            {RESPONSIBILITIES.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] sm:pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>

          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            8. TERMINATION OF THE CONTRACT.
          </p>
          <div className="pt-1">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              Within the framework of the present contract, Traffix Marketing
              online SCP is obliged to:
            </p>
          </div>
          <ol class="mt-2">
            <di className="flex justify-start">
              <p className="text-[24px] pr-3 pl-1 font-[400]">8.1.</p>
              <li className="sm:text-[24px] text-[16px] font-[400]">
                The term of the contract will end when any of the following
                causes occur:
              </li>
            </di>
          </ol>
          <ol class="p-5">
            {TERMINATION.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            9. MODIFICATIONS OF THE SERVICE.
          </p>

          <ol class="mt-2">
            {MODIFICATIONS.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            10. MODIFICATIONS OF THE SERVICE.
          </p>

          <ol class="mt-2">
            {SERVICE.map((item, index) => (
              <di className="flex justify-start">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">11. CLAIMS.</p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              The Client will be able to address Traffix Marketing online SCP,
              through the Customer Services, to present claims about the
              operation and quality of the Service, the application of prices,
              the invoicing, or any other question that could arise in relation
              to the provision of the Service. The Client will be able to make
              the claims in the term of 1 month from the event motivating the
              claim, addressing to Traffix Marketing online SCP Avinguda Josep
              Tarradellas 50, 08029, Barcelona, CIF J-67363887,
              Mail.:datasafe@traffix.es.. Once the claim has been formulated, if
              the Client has not obtained a satisfactory response from Traffix
              Marketing online SCP within a period of 1 month, he/she may
              address his/her claim to the Consumer Arbitration Boards. The
              corresponding arbitration must be expressly accepted by Traffix
              Marketing online SCP. The Customer may also contact the Secretary
              of State for Telecommunications and the Information Society. The
              Service will not be suspended or interrupted while the claim is
              being substantiated, provided that the Customer reliably consigns
              the amount owed, delivering the corresponding receipt to Traffix
              Marketing online SCP. The Customer has the right to obtain a
              document certifying the content of his claim as well as a
              reference number of the same. Official Claim Forms will be
              available to the Customer at the above address.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            12. PROTECTION OF PERSONAL DATA.
          </p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              For the purposes set forth in Organic Law 15/1999, of December 13,
              on the Protection of Personal Data, its Development Regulations
              and in Law 32/2003, of November 23, General Telecommunications
              Law, Traffix Marketing online SCP informs the Customer that their
              data will be incorporated into an automated personal data file
              created by Traffix Marketing online SCP and under its
              responsibility, as they are necessary for the purposes of
              maintenance, development and control of their contractual
              relationship, and the management of Traffix Marketing online SCP
              Services. The Customer is informed that the following data will be
              processed, by way of example, corresponding to its traffic and
              billing: the telephone number assigned to the Customer, location,
              type of terminal used, destination number, duration of calls made
              and/or volume of data transmitted, date of the call or of the
              Service, and data relating to the payment and those others that in
              the future may be revealed as necessary for the management of the
              traffic and billing of the Services. Likewise, THE CUSTOMER gives
              his consent to Traffix Marketing online SCP to use his data for
              the purpose of sending commercial information and advertising
              relating to services or products of Traffix Marketing online SCP
              that may be of interest to the CUSTOMER. If you do not wish to
              receive such advertising, you may express your will to the
              contrary at any time by contacting Traffix Marketing online SCP at
              the address Traffix Marketing online SCP Avinguda Josep
              Tarradellas 50, 08029, Barcelona - CIF J-67363887,
              Mail.:datasafe@traffix.es, When the Customer has provided a mobile
              number or email address, Traffix Marketing online SCP may also
              send the Customer information regarding Traffix Marketing online
              SCP products and/or services contracted, in addition to postal or
              telephone delivery, via SMS or email. The Customer gives his
              consent to Traffix Marketing online SCP to communicate his
              personal data to those entities that are linked to Traffix
              Marketing online SCP, or collaborate with it in the contracting,
              provision and maintenance of the Services, for purposes of
              maintenance, development and control of the Services or for
              commercial information and advertising related to services or
              products of Traffix Marketing online SCP. Likewise, Traffix
              Marketing online SCP may check the solvency of the Client, by
              itself or by anyone acting on its behalf and interest, by
              accessing reports from financial institutions and/or automated
              files in which personal data have been obtained in accordance with
              the provisions in force, as well as to verify the accuracy of the
              data provided by the Client. By signing the Contract, the Client
              authorizes the automated processing and maintenance of this data
              on financial solvency for statistical purposes and for the
              evaluation of solvency and risk assessment prior to the activation
              of the Services, which will be cancelled when they are no longer
              necessary or relevant for the purpose for which they were
              collected or recorded. We inform you that in case of non-payment
              of all or any of the contracted services, Traffix Marketing online
              SCP may communicate this fact to files, services and information
              entities on solvency and creditworthiness in accordance with
              current regulations. Traffix Marketing online SCP is committed to
              fulfilling its duty to maintain the secrecy of personal data, and
              take the necessary measures to prevent alteration, loss, treatment
              or unauthorized access, taking into account at all times the state
              of technology. The Client is informed of its right to exercise at
              any time the rights of access, rectification, opposition and
              cancellation in accordance with the provisions of Law 15/1999, of
              December 13, Protection of Personal Data and other applicable
              regulations to that effect, and must contact Traffix Marketing
              online SCP Avinguda Josep Tarradellas 50, 08029, Barcelona - CIF
              J-67363887, Mail.:datasafe@traffix.es for this purpose in the
              manner and conditions provided in the aforementioned regulations.
              The Customer gives his consent to Traffix Marketing online SCP to
              record the calls that the Customer may make or receive from the
              customer service. Such recording is done in order to improve the
              quality of the services provided under the contract, to verify the
              satisfaction of the Customer, as well as to prove the contract.
              The customer authorizes Traffix Marketing online SCP to provide
              their data to a third party for the purpose of billing
              distributors and recruiters.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            13. ASSIGNMENT TO THIRD PARTIES.
          </p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              The Customer is obliged to pay the amounts due for the use of the
              Services provided by Traffix Marketing online SCP, to make proper
              use of the Service and not to assign its position in this
              Agreement, unless express consent, in writing, by Traffix
              Marketing online SCP. This Service is provided to the Customer as
              an end Customer, for its exclusive use, in no case may the
              Customer resell or distribute the Service to third parties.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
          14. INTELLECTUAL PROPERTY.
          </p>

          <ol class="my2-2">
            {INTELLECTUAL.map((item, index) => (
              <di className=" flex justify-start py-1">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol> 
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
          15. CONFIDENTIALITY.
          </p>

          <ol class="mt-2">
            {CONFIDENTIALITY.map((item, index) => (
              <di className="flex justify-start py-1">
                <p className="sm:text-[24px]  text-[16px] pr-2 font-[400]">{item.itms}</p>
                <li className="sm:text-[24px] text-[16px] font-[400]">{item.paragraph}</li>
              </di>
            ))}
          </ol>



          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            16. DURATION, TERMINATION AND CANCELLATION OF THE CONTRACT.
          </p>
          

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              This contract is indefinite, unless it is terminated, with respect
              to any or all of the services, for any of the following reasons:
              If you as a Client wish to terminate it: you have the right to do
              so at any time provided that you give us prior notice, at least
              fifteen calendar days in advance. The cancellation will be made by
              calling the Customer Service Center of Traffix Marketing online
              SCP indicating the necessary information to ensure the correct and
              secure processing. The Agreement may also be terminated by mutual
              agreement between the parties. In the event that the Customer has
              registered for the Service through the website or by telephone,
              the Customer may freely withdraw from the Agreement within seven
              (7) working days from the day of receipt of the Agreement. You are
              entitled to be provided with a reference number that will allow
              you to verify the day of the cancellation request and to have a
              record of the cancellation. If the cancellation has been requested
              by telephone, you may also request that we send you a document
              certifying your request. Unless the cause is attributable to you,
              we will not invoice you for any amounts accrued after the
              fifteen-day period in which the cancellation should have taken
              effect. In the case of offers conditioned to a minimum period of
              permanence, if you decide to cancel before the established term,
              such cancellation will result in the loss of benefits or offers
              linked to the permanence. Technical reasons: We may terminate the
              contract at any time for strictly technical reasons that make it
              impossible to provide the service. Breach: If either party, you or
              us, breaches the obligations to which it is committed in this
              contract, the other party may immediately terminate the contract
              and discontinue the services, communicating it to the defaulting
              party and without prejudice to the claim of the amounts owed by
              the Customer to Traffix Marketing online SCP for the provision of
              its services and products.
            </p>
          </div>
          <p className="text-[24px] font-bold mt-2">
            17. VALIDITY OF THE CONTRACT.
          </p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              The Conditions of Provision of the Services subject of this
              Agreement may be modified when the conditions of the service or
              the applicable regulation so require. The modifications will be
              made in accordance with the legislation in force and prior written
              notice to the Customer with a notice period of one (1) month. Once
              this period has elapsed without Traffix Marketing online SCP
              having received any communication, it will be understood that the
              Client accepts the modifications. In case of incompatibility
              between the General Terms and Conditions and the Specific Terms
              and Conditions applicable to each of the Services contracted by
              the Customer, the Specific Terms and Conditions shall prevail over
              the General Terms and Conditions. If for technological reasons
              Traffix Marketing online SCP could not provide the Service to the
              Customer under the agreed terms, Traffix Marketing online SCP
              undertakes to inform the Customer immediately of this
              circumstance. The Customer may choose in this case to hire another
              Service or to terminate the Agreement. If the impossibility was
              due to the Customer Traffix Marketing online SCP will invoice in
              any case those costs for the provision of the Service have been
              generated. The Customer for the provision of the Service gives
              Traffix Marketing online SCP, by signing this Agreement the
              necessary powers for the management and processing of such
              services to the relevant entities. The invalidity or impossibility
              of application or execution of any provision of this Agreement
              shall not affect the rest unless it implies a substantial and
              serious alteration of the same.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">
            18. LIMITATION OF LIABILITY.
          </p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              In any case, the total liability of Traffix Marketing online SCP
              (unless there is intent or gross negligence), will be limited to
              the total amount of a monthly fee actually paid by the Customer
              under this Agreement, expressly excluding any liability for direct
              or indirect damages, consequential damages and / or lost profits.
            </p>
          </div>
          <p className="sm:text-[24px] text-[17px] font-bold mt-2">19. COMMUNICATIONS.</p>

          <div className="sm:p-7">
            <p className="sm:text-[24px] text-[16px] font-[400]">
              The parties agree that any communications to be made in connection
              with the provisions of this Agreement and in the performance
              hereof shall be made in writing and shall be sent by mail, as
              appropriate, to the address designated by the Customer and to the
              Customer Service Department of Traffix Marketing online SCP 20.
              APPLICABLE LEGISLATION. The Customer contracts with Traffix
              Marketing online SCP subject in case of dispute to the provisions
              of RDL 3/2011 approving the revised text of the Public Sector
              Contracts Act. In case of incompatibility between the General
              Conditions and the Specific Conditions applicable to each of the
              Services contracted by the Customer, the specific conditions shall
              prevail over the General Traffix Marketing online SCP and the
              Customer expressly waiving any other jurisdiction, are subject to
              the Courts and Tribunals of the user's domicile for any dispute
              that may arise from this legal notice. In the event that the user
              is domiciled outside Spain, Traffix Marketing online SCP and the
              user submit, expressly waiving any other jurisdiction, to the
              Courts and Tribunals of the city of Barcelona (Spain).
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Conditions;
