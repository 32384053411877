import React, { useState } from "react";
import Footer from "../../components/footer";
import TopHeader from "../../components/topHeader";
import MyComponent from "../../components/map";
import axiosClient from "../../helper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "../../components/header";

const Contact = () => {
  const [contactForm, setContactForm] = useState({
    sure_name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });
  const [authorized, setAuthorized] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleOpen1 = () => {
    setIsOpen1(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleClose1 = () => {
    setIsOpen1(false);
  };
  const handleChange = (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    console.log(e.target.checked);
    setAuthorized(e.target.checked);
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const sendMessage = () => {
    if (
      contactForm.sure_name === "" ||
      contactForm.company === "" ||
      contactForm.email === "" ||
      contactForm.phone === "" ||
      contactForm.message === ""
    ) {
      handleOpen();
    } else if (contactForm.email && !validateEmail(contactForm.email)) {
      handleOpen1();
    } else {
      setLoader(true);
      let data = {
        ...contactForm,
        authorized,
      };

      axiosClient()
        .post("/contact/createContact", data)
        .then((res) => {
          toast.success(res.data.message);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          toast.error(error.response.data.message);
          return error;
        });
    }
  };

  return (
    <React.Fragment>
      <Header />
      <TopHeader text="Contact" />
      <div className="lg:pl-[100px] pl-[5px] ">
        <div className="grid grid-cols-12">
          <div className=" lg:col-span-6 md:col-span-12  col-span-12">
            <div className="sm:px-[70px] px-[6px] lg:py-[100px] py-[20px] ">
              <h1 className="font-bold text-3xl">We’d love to hear from you</h1>
              <p className="text-lg font-medium mt-7">Name and surname</p>
              <input
                type="text"
                placeholder="Enter your name"
                name="sure_name"
                value={contactForm.sure_name}
                onChange={handleChange}
                className="h-[46px] border-[1.5px] border-[#ACACAC] w-full mt-4 indent-2 rounded-md"
              />
              <p className="text-lg font-medium mt-5">Company</p>
              <input
                type="text"
                placeholder="Enter your company name"
                name="company"
                value={contactForm.company}
                onChange={handleChange}
                className="h-[46px] border-[1.5px] border-[#ACACAC] w-full mt-4 indent-2 rounded-md"
              />
              <p className="text-lg font-medium mt-5">Email</p>
              <input
                type="text"
                placeholder="Enter your Email address"
                name="email"
                value={contactForm.email}
                onChange={handleChange}
                className="h-[46px] border-[1.5px] border-[#ACACAC] w-full mt-4 indent-2 rounded-md"
              />
              <p className="text-lg font-medium mt-5">Phone</p>
              <input
                type="text"
                placeholder="Enter your Phone number"
                name="phone"
                value={contactForm.phone}
                onChange={handleChange}
                className="h-[46px] border-[1.5px] border-[#ACACAC] w-full mt-4 indent-2 rounded-md"
              />
              <p className="text-lg font-medium mt-5">Message</p>
              <textarea
                type="text"
                placeholder="Enter your  message"
                name="message"
                value={contactForm.message}
                onChange={handleChange}
                className="h-[147px] border-[1.5px] border-[#ACACAC] w-full mt-4 indent-2 inde rounded-md"
              />
              <div className="flex mt-4">
                <input
                  type="checkbox"
                  name="authorized"
                  value={authorized}
                  onChange={handleCheck}
                  className="h-[14px] w-[14px] border-[1.5px] border-[#ACACAC]  indent-2 rounded-md mt-2"
                />
                <p className="ml-3">
                  I authorize the processing of my data in order to respond to
                  the queries raised.
                </p>
              </div>
              <p className="mt-5 text-[15px]">
                Responsible for the file: Data-Safe . Purpose: sending
                information about products and services to the subscriber.
                Legitimation: consent. Recipients: The data will not be
                communicated to third parties. Rights: access, rectify and
                delete data, as well as other rights, as explained in the
                additional information. You can consult additional and detailed
                information in our Policy
              </p>
              <div className="flex sm:justify-start justify-start">
                <button
                  onClick={sendMessage}
                  className="flex max-w-[250px] w-[100%] justify-center  items-center border-0 text-white bg-[#05164D]  py-3 font-[700] mt-4"
                >
                  {loader ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div className="flex">
                      SEND MESSAGE
                      <img
                        src="Group (4).svg"
                        alt=""
                        height="10px"
                        width="14px"
                        className="ml-8"
                      />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className=" lg:col-span-6 md:col-span-12  col-span-12">
            <img src="/map.png" alt="" className="h-[100%]" />
            {/* <div className="-z-20">
              <MyComponent />
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
      <div
        className={`fixed inset-0 flex items-center justify-center z-20 ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300 ${
          isClosing ? "ease-in" : "ease-out"
        }`}
      >
        <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div className="bg-white rounded-lg font-sans p-4 z-50 w-[400px]">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-[#F0AD02]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>

            <h2 className="text-lg font-semibold mb-4 ml-4">Attention</h2>
          </div>
          <p className="mb-4">
            Please fill the form carefully. Some fields are not completed
            correctly
          </p>
          <button
            className="bg-[#F0AD02]  text-white font-bold py-2 px-4 rounded"
            onClick={handleClose}
          >
            Okay!
          </button>
        </div>
      </div>
      <div
        className={`fixed inset-0 flex items-center justify-center z-20 ${
          isOpen1 ? "opacity-100" : "opacity-0 pointer-events-none"
        } transition-opacity duration-300 ${
          isClosing ? "ease-in" : "ease-out"
        }`}
      >
        <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
        <div className="bg-white rounded-lg font-sans p-4 z-50 w-[400px]">
          <div className="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-[#F0AD02]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
              />
            </svg>

            <h2 className="text-lg font-semibold mb-4 ml-4">Attention</h2>
          </div>
          <p className="mb-4">Email format is not correct</p>
          <button
            className="bg-[#F0AD02]  text-white font-bold py-2 px-4 rounded"
            onClick={handleClose1}
          >
            Okay!
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
