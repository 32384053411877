import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="absolute w-full top-4">
        <div className="w-[100%] text-white">
          <div className="sm:flex block justify-between sm:px-20 px-3">
            <img
              src="/Group (3).svg"
              alt="some"
              className="h-auto w-[150px] cursor-pointer"
              onClick={() => navigate("/")}
            />
            <div className="max-w-[400px] w-[100%] font-semibold  flex justify-between items-center px-4">
              <p
                className="hover:text-[#FFAD01] cursor-pointer"
                onClick={() => navigate("/price")}
              >
                Price
              </p>
              <p
                className="hover:text-[#FFAD01] cursor-pointer"
                onClick={() => navigate("/faq")}
              >
                FAQs
              </p>
              <p
                className="hover:text-[#FFAD01] cursor-pointer"
                onClick={() => navigate("/contact")}
              >
                Contact Us
              </p>
              <Link
              to={'https://users.data-safe.net/'}
              >
                 <button className="bg-[#FFAD01] px-3 py-2">Login</button>

              </Link>
                         </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
