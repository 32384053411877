import React from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import TopHeader from "../../components/topHeader";

const Cookies = () => {
  return (
    <React.Fragment>
      <Header />
      <TopHeader />
      <div className="lg:px-[250px] md:px-[47px] px-6 sm:py-[80px] py-[20px]">
        <h1 className="font-[700] sm:text-[22px] text-[14px]">
          INFORMATION ON THE USE OF COOKIES
        </h1>
        <h1 className="font-[700] sm:text-[22px] text-[14px] mt-5">
          What are cookies?
        </h1>
        <p className="sm:text-[22px] text-[16px] mt-3">
          Cookies are small data files that are received on the terminal from
          the visited Web site and are used to record certain browsing
          interactions on a Web site by storing data that can be updated and
          retrieved. These files are stored on the user's computer and contain
          anonymous data that are not harmful to your computer. They are used to
          remember the user's preferences, such as the selected language, access
          data or page personalization.
        </p>
        <p className="sm:text-[22px] text-[16px] mt-3">
          Cookies can also be used to record anonymous information about how a
          visitor uses a site. For example, from which Web page you have
          accessed, or if you have used an advertising banner to get there.
        </p>
        <h1 className="font-[700] sm:text-[22px] text-[14px] mt-5">
          What use do we make of the different types of cookies?
        </h1>
        <p className="sm:text-[22px] text-[16px] mt-3">
          According to their purpose: Technical cookies Technical cookies are
          those that facilitate user navigation and the use of the different
          options or services offered by the web, such as identifying the
          session, allowing access to certain areas, facilitating orders,
          purchases, filling out forms, registration, security, facilitating
          functionalities (videos, social networks...)
          <br /> Customization cookies Customization cookies allow users to
          access services according to their preferences (language, browser,
          configuration...)
          <br /> Analytical cookies Analytical cookies are those used to carry
          out anonymous analysis of the behavior of web users and to measure
          user activity and develop browsing profiles in order to improve
          websites
          <br /> Advertising Cookies Advertising cookies allow the management of
          advertising space on the web In addition, these cookies can be
          personalized advertising and thus allow the management of advertising
          space on the web based on the behavior and browsing habits of the
          user, from which your profile is obtained and allow you to customize
          the advertising displayed in the user's browser or other profiles and
          social networks of the user.
          <br /> According to its term:
          <br /> Session cookies Session cookies are those that last as long as
          the user is browsing the website and are deleted when the browser is
          closed
          <br /> Persistent Cookies These cookies are stored in the user's
          terminal until they are manually deleted or the duration period
          established for the cookie has expired
          <br /> According to its ownership:
          <br /> Own Cookies Those that are sent to the user's terminal
          equipment from a computer or domain managed by the editor itself and
          from which the service requested by the user is provided Third-party
          cookies Those that are sent to the user's terminal equipment from a
          computer or domain that is not managed by the editor, but by another
          entity that processes the data obtained through cookies
          <br /> The following table shows the classification and description of
          the cookies used on this website so that you can identify them in your
          browser:
          <br /> Name Holder Data collected Purpose Duration
          <br /> John Doe john@example.com
          <br /> _ga Own cookie Visitor sessions. Used to distinguish users.
          Analytics 2 years
          <br /> _gid Own cookie Visitor sessions. Used to distinguish users.
          Analytics. 1 day.
          <br /> _gat_gtag_UA_[ID] Own cookie Visitor sessions. Used to limit
          the percentage of requests. Analytics. 1 minute.
          <br /> IDE Third-party cookie (doubleclick.net) Preferences, links,
          conversions and interactions with advertisements Advertising. 1 year.
          <br />
          _gcl_au Own cookie Visitor sessions. Advertising. 3 months.
          <br />
          laravel_cookie_consent Own cookie Acceptance of cookies by the user
          Technical. 20 years. XSRF-TOKEN Proprietary cookie This cookie is used
          to assist with site security in the prevention of cross-site request
          forgery attacks (CSRF) Technical. At the end of the session with the
          browser.
          <br /> test_cookie Third-party cookie (doubleclick.net) To check if
          the user's browser supports cookies Technical/ Advertising Less than 1
          minute. 15 minutes.
          <br /> Traffix Marketing online S.C.P. (Data-Safe) uses its own and
          third-party technical, personalization, analytical and advertising
          cookies, which process connection and/or device data, as well as
          browsing habits for statistical and advertising purposes.
          <br />
          <br /> Therefore, when accessing our website, in compliance with
          Article 22 of the Law on Information Society Services, we have
          requested your consent for its use. <br />
          <br /> The provision of personal data through our portal and consent
          to the use of cookies requires a minimum age of 14 years and the
          express acceptance of our Privacy Policy. <br />
          <br /> In any case, we inform you that you can enable or disable
          cookies by following the instructions of your Internet browser:
          <br /> Chromes Settings 🡪 Show advanced options 🡪 Privacy 🡪 Content
          settings. support.google.com Firefox Tools 🡪 Options 🡪 Privacy 🡪
          History 🡪 Custom Settings. support.mozilla.org
          <br /> Internet Explorer Tools 🡪 Internet Options 🡪 Privacy 🡪
          Settings. windows.microsoft.com <br />
          Opera Tools 🡪 Preferences 🡪 Edit preferences 🡪 Cookies.
          <span className=" lg:text-[24px] md:text-[24px] text-[12px]">
            {" "}
            http://www.opera.com/help/tutorials/security/cookies/
          </span>
          <br /> Safari Preferences 🡪 Security. http://www.apple.com
          <br /> Edge Settings 🡪 View advanced settings 🡪 Privacy and services 🡪
          Cookies. https://
          <br />
          privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy
        </p>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Cookies;
