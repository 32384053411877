import React from "react";
import {
  termsPara,
  termstwo,
  OBLIGATIONS,
  RESPONSIBILITIES,
  TERMINATION,
  MODIFICATIONS,
  SERVICE,
  INTELLECTUAL,
  CONFIDENTIALITY,
} from "../../data";

const Disclaimer = () => {
  return (
    <React.Fragment>
      <div className="max-w-[1054px] w-[100%] m-auto">
          <div className="">
            <p className="sm:text-[22px] text-[16px] font-[400] my-12">
              This website is owned by Traffix Marketing online SCP (hereinafter
              "Data-Safe"), CIF:<br/> J-67363887, domiciled at Avinguda Josep
              Tarradellas 50, 08029, Barcelona, Spain.<br/> For any inquiry or
              proposal, please contact us by phone at  +34 900 83 82 30 or by
              e-mail at<br/> Data-Safe@traffix.es
            </p>

            <p className="text-[24px] font-[400] my-7">
              This website is governed by the regulations exclusively applicable
              in Spain, being subject to it,<br/> both nationals and foreigners who
              use this website
            </p>
            <p className="text-[24px] font-[400] my-7">
              Access to our website by the USER is free of charge and is subject
              to the prior reading and full, express and unreserved acceptance
              of these GENERAL CONDITIONS OF USE in force at the time of access,
              which we ask you to read carefully. The USER, when using our
              portal, its contents or services, expressly accepts and submits to
              the general conditions of use of the same. If the USER does not
              agree with these conditions of use, he/she must refrain from using
              this website and operating through it.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The provision of personal data through our portal requires a
              minimum age of 14 years, or if applicable, to be of legal age to
              contract
            </p>
            <p className="text-[24px] font-[400] my-7">
              At any time we may modify the presentation and configuration of
              our Web, expand or reduce services, and even remove it from the
              Web, as well as the services and content provided, all
              unilaterally and without prior notice.
            </p>
          </div>

          <p className="text-[24px] font-bold mt-2">
            A. INTELLECTUAL AND INDUSTRIAL PROPERTY
          </p>

          <div className="">
            <p className="text-[24px] font-[400] my-7">
              All intellectual and/or industrial property rights relating to the
              contents of the Web Site, as well as any element related to the
              provision of the Service and/or infrastructure used to provide the
              Service are owned by Data-Safe or third parties unless otherwise
              indicated. This includes, but is not limited to, any text,
              trademark, trade name, logo, distinctive sign, drawing,
              photograph, video, audio-visual content, image, icon, file,
              graphic design, color scheme, website structure and design,
              computer code and/or applications.
            </p>
            <p className="text-[24px] font-[400] my-7">
              THE USER is prohibited from reproducing, transforming,
              distributing, communicating and/or exploiting by any means the
              contents of the Web Site without prior express authorization from
              Data-Safe. Likewise, the modification, reproduction, duplication,
              copying, distribution, sale, resale and other forms of
              exploitation for commercial or equivalent purposes of the Service
              is strictly prohibited.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER is not authorized to delete, modify, amend or manipulate
              any protection device or security system that may be installed on
              the Website.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The fact that Data-Safe gives access to the USER to its services
              does not imply any waiver, transfer, total or partial assignment
              of intellectual and industrial property rights by Data-Safe and
              does not entitle the USER to any use of the Website that exceeds
              the actions necessary to visit the Website and to use the Service
              offered through the same. The USER shall refrain from reproducing
              or copying for private use any content of this website that may be
              considered subject to protection of intellectual or industrial
              property rights.
            </p>
          </div>

          <p className="text-[24px] font-bold mt-2">
            {" "}
            B. CONDITIONS OF ACCESS AND USE{" "}
          </p>

          <div className="">
            <p className="text-[24px] font-[400] my-7">
              Access to our Web site is free of charge. However, certain
              services are restricted to certain USERS and require a
              registration process and/or identification by means of passwords.
              These services will be duly identified on the Web.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The sending of personal data implies the express acceptance by the
              USER of our privacy policy.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER must access our website in accordance with good faith,
              the rules of public order and these General Conditions of Use.
              Access to our Web site is at the sole responsibility of the USER,
              who shall be liable in all cases for any damages that may be
              caused to third parties or to us.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER is expressly prohibited from using and obtaining the
              services and contents offered on this web page by procedures other
              than those stipulated in these conditions of use and, if
              applicable, in the specific conditions that regulate the
              acquisition of our services.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER is prohibited from any action on our website that causes
              an excessive overload of our computer systems, as well as the
              introduction of viruses, or installation of robots, or software
              that alters the normal operation of our website, or ultimately may
              cause damage to our computer systems.
            </p>
            <p className="text-[24px] font-[400] my-7">
              Taking into account the difficulty of control over the
              information, content and services contained in third party
              websites available from our website, we inform you that Data-Safe
              is not responsible for them, although we will try, as far as
              possible, to ensure their legality.
            </p>
            <p className="text-[24px] font-[400] my-7">
              Data-Safe reserves the right to terminate the membership of any
              USER that the organization believes has violated the conditions
              governing the use of our website. It also reserves the right to
              take appropriate legal action against those who violate these
              general conditions of use.
            </p>
          </div>
          <p className="text-[24px] font-bold mt-2">C. PRIVACY POLICY</p>

          <div className="">
            <p className="text-[24px] font-[400] my-7">
              Confidentiality and security are core values of Data-Safe and,
              consequently, we are committed to ensuring the privacy of the USER
              at all times and not to collect unnecessary information.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The provision of personal data through our portal requires a
              minimum age of 14 years and the express acceptance of our Privacy
              Policy.
            </p>
          </div>
          <p className="text-[24px] font-bold mt-2">D. RESPONSIBILITIES</p>

          <div className="">
            <p className="text-[24px] font-[400] my-7">
              By making available to the USER this website we want to offer a
              quality service, using the utmost diligence in the provision of
              the same, as well as in the technological means used. However,
              Data-Safe does not guarantee that the availability of the service
              will be continuous and uninterrupted, due to circumstances caused
              by problems in the Internet network, breakdowns in computer
              devices or other unforeseeable circumstances, so that the USER
              agrees to bear within reasonable limits these circumstances.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER acknowledges that he/she has understood all the
              information regarding the conditions of use of our portal, and
              acknowledges that they are sufficient for the exclusion of the
              error in them, and therefore, accepts them fully and expressly.
            </p>
            <p className="text-[24px] font-[400] my-7">
              The USER is fully aware that the mere navigation through this
              website, as well as the use of its services, implies the
              acceptance of these conditions of use.
            </p>
            <p className="text-[24px] font-[400] my-7">
              All matters relating to our website are governed exclusively by
              Spanish law. In the event of any discrepancy or difference between
              the parties in relation to the interpretation and content of this
              website, all parties submit to the Courts and Tribunals that
              legally correspond.
            </p>
          </div>
          <p className="text-[24px] font-bold mt-2">
            E. VALIDITY OF THE GENERAL CONDITIONS OF ACCESS TO THE WEBSITE
          </p>

          <div className=" my-7">
            <p className="text-[24px] font-[400]">
              These General Terms and Conditions of Use were modified on
              07/21/2022. We may modify them at any time: please check the date
              of issue each time you connect to our website to ensure that no
              changes have been made that may affect you.
            </p>
          </div>
        
      </div>
    </React.Fragment>
  );
};

export default Disclaimer;
