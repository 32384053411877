import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <div className="bg-[#05164D] ">
        <div
          style={{
            backgroundImage: "url(/footerbg.png)",
            // height: "473px",
            // background: "red",
            width: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="grid grid-cols-12 pt-20 sm:py-[70px] sm:px-[200px]">
            <div className="sm:col-span-4  col-span-12 ">
              <div className="flex justify-center items-center">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[200px] h-auto "
                />
              </div>
            </div>
            <div className="sm:col-span-4 col-span-12">
              <div className="flex sm:justify-center px-5 sm:items-center text-white">
                <div>
                  <h1
                    className="text-[20px] font-[700] my-4"
                    style={{ lineHeight: "32px" }}
                  >
                    Page
                  </h1>
                  <p
                    className="text-[16px] font-[400] my-4 cursor-pointer"
                    onClick={() => {
                      navigate("/faq");
                      window.scrollTo(0, 0);
                    }}
                  >
                    FAQS
                  </p>
                  <p
                    className="text-[16px] font-[400] my-4 cursor-pointer"
                    onClick={() => {
                      navigate("/contact");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Contact us
                  </p>
                  <p
                    className="text-[16px] font-[400] my-4 cursor-pointer"
                    onClick={() => {
                      navigate("/price");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Prices
                  </p>
                  <p
                    className="text-[16px] font-[400] my-4 cursor-pointer"
                    onClick={() => {
                      navigate("/");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Home
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-4 col-span-12">
              <div className="flex sm:justify-center px-5 sm:items-center text-white">
                <div>
                  <h1
                    className="text-[20px] font-[700] my-4"
                    style={{ lineHeight: "32px" }}
                  >
                    Resources
                  </h1>
                  <p
                    className="text-[16px] font-[400] my-4 cursor-pointer"
                    onClick={() => {
                      navigate("/terms");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Terms and conditions
                  </p>
                  <p
                    className="text-[16px] font-[400] cursor-pointer my-4 "
                    onClick={() => {
                      navigate("/cookies");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Cookie policy
                  </p>
                  <p
                    className="text-[16px] font-[400] cursor-pointer first-letter: my-4"
                    onClick={() => {
                      navigate("/privacy");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Privacy policy
                  </p>
                  <p
                    className="text-[16px] font-[400] cursor-pointer my-4"
                    onClick={() => {
                      navigate("/disclimer");
                      window.scrollTo(0, 0);
                    }}
                  >
                    Disclaimer
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#011247] p-5 text-white">
        <p className="flex text-[16px]   font-normal justify-center items-center text-center">
          {" "}
          © Copyrights 2022 DataSafe All rights reserved.
        </p>
      </div>
    </React.Fragment>
  );
};

export default Footer;
