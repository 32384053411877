import React, { useState } from "react";
import Pricing from "../../components/pricing";
import Footer from "../../components/footer";
import TopHeader from "../../components/topHeader";
import Header from "../../components/header";
import { Modaldata, Modaldata3 } from "../../data";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../../components/PaymentForm";
import { Link } from "react-router-dom";
import axiosClient from "../../helper";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const stripePromise = loadStripe(
  "pk_test_51L8gJIGcwuk8geTI0E5zDxZ1Vw0YlwkeGu6tPGlmejGVTGfwf7Kptw43B1IRmjPnWVdHJ8DvYO4DnPrGHDBj11oq00dXP7FMim"
);
const Price = () => {
  const [settingState, setSettingState] = useState(false);
  const [counter, setCounter] = useState(100);
  const [count, setCount] = useState(100);
  const [iconsModal, setIconsModal] = useState(false);
  const [ModalFor, setModalFor] = useState(false);
  const [ModalDevice, setModalDevice] = useState(false);
  const [ModalCondition, setModalCondition] = useState(false);
  const [ModalCapacity, setModalCapacity] = useState(false);
  const [ModalCradit, setModalCradit] = useState(false);
  const [whereDataStore, setWhereDataStore] = useState();
  const [isDivSelected, setIsDivSelected] = useState(false);
  const [whatAreDevices, setWhatAreDevices] = useState(false);
  const [whatAreDevicesIndex, setWhatAreDevicesIndex] = useState(false);
  const [email, setEmail] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [gbValue, setGbValue] = useState(16);
  const [gbValue1, setGbValue1] = useState(16);
  const [cartHolderName, setCardHolderName] = useState();
  const [country, setCountry] = useState();
  const [storeData, setStoreData] = useState();
  const [storeDevice, setStoreDevice] = useState();
  const [modals, setModals] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");

  const closeNavbar = () => {
    setSettingState(false);
    setIconsModal(false);
    setModalFor(false);
    setModalDevice(false);
    setModalCondition(false);
    setModalCapacity(false);
    setModalCradit(false);
  };
  const increase = () => {
    setCount((count) => count + 10);
    if (count < 100) {
      setGbValue(16);
    } else {
      setGbValue(gbValue + 1.6);
    }
  };
  const increase1 = () => {
    setCounter((counter) => counter + 10);

    if (counter < 100) {
      setGbValue1(16);
    } else {
      setGbValue1(gbValue1 + 1.6);
    }
  };
  const decrease = () => {
    if (count > 100) {
      setCount((count) => count - 10);
    }

    if (count > 100) {
      setGbValue(gbValue - 1.6);
    } else {
      setGbValue(16);
    }
  };
  const decrease1 = () => {
    if (counter > 100) {
      setCounter((counter) => counter - 10);
    }

    if (counter > 100) {
      setGbValue1(gbValue1 - 1.6);
    } else {
      setGbValue1(16);
    }
  };

  const handleClick = (data, index) => {
    if (index === whereDataStore) {
      setIsDivSelected(false);
      setWhereDataStore("");
    } else {
      setIsDivSelected(true);
      setWhereDataStore(index);
      if (data.paragraph === "Computer") {
        setStoreData("computer");
      } else if (data.paragraph === "Laptop") {
        setStoreData("laptop");
      } else {
        setStoreData("external");
      }
    }
  };
  const setting = () => {
    setSettingState(!settingState);
    setIconsModal(false);
  };
  const OpenFormodal = () => {
    setModals(2);
    if (isDivSelected) {
      setIconsModal(false);
      setModalFor(true);
    } else {
      alert("please Select Where to store data");
    }
  };
  const OpenDevice = () => {
    setModals(3);
    if (whatAreDevices) {
      setModalFor(false);
      setModalDevice(true);
    } else {
      alert("Please Select What are these devices for?");
    }
  };
  const Opencondition = () => {
    setModals(4);
    setModalDevice(false);
    setModalCondition(true);
  };
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const OpenCapacity = () => {
    let data = {
      email,
      data_storage: storeData,
      gbs: parseInt(counter),
      useage: storeDevice,
    };
    axiosClient()
      .post(`/user/signUp`, data)
      .then((response) => {
        setModals(5);
        if (email === "") {
          alert("please enter email address");
        } else if (email && !validateEmail(email)) {
          alert("Invalid email format");
        } else if (checkBox === false) {
          alert("please check terms and conditions");
        } else {
          setModalCondition(false);
          setModalCapacity(true);
        }
      })
      .catch((error) => {
        toast.success(error.response.data.message);
        return error;
      });
  };
  const OpenCradit = () => {
    setModals(6);
    setModalCapacity(false);
    setModalCradit(true);
  };
  const OpenSecondModal = () => {
    setModals(1);
    setSettingState(false);
    setIconsModal(true);
  };
  const submitModal = () => {
    setModalCradit(!ModalCradit);
    setModals(0);
  };
  const handleDevice = (data, index) => {
    console.log("data", data);

    if (whatAreDevicesIndex === index) {
      setWhatAreDevices(false);
      setWhatAreDevicesIndex("");
    } else {
      setWhatAreDevices(true);
      setWhatAreDevicesIndex(index);
      if (data.paragraph === "Personal") {
        setStoreDevice("personal");
      } else {
        setStoreDevice("business");
      }
    }
  };
  const handleModal = () => {
    if (modals === 0) {
      setSettingState(true);
    } else if (modals === 1) {
      setSettingState(false);
      setIconsModal(true);
    } else if (modals === 2) {
      setIconsModal(false);
      setModalFor(true);
    } else if (modals === 3) {
      setModalFor(false);
      setModalDevice(true);
    } else if (modals === 4) {
      setModalDevice(false);
      setModalCondition(true);
    } else if (modals === 5) {
      setModalCondition(false);
      setModalCapacity(true);
    } else if (modals === 6) {
      setModalCapacity(false);
      setModalCradit(true);
    }
  };
  return (
    <React.Fragment>
      <Header />
      <TopHeader text="Prices" />
      <div>
        <div className="max-w-[895px] pt-[60px] m-auto ">
          <div className="flex justify-center items-center text-center">
            <p className="lg:text-[32px] md:text-[32px] sm:text-[20px] text-[25.57px] px-5  text center font-bold font-sans justify-center item center">
              Calculate the estimated prices for the data you are going to
              secure using Data-Safe:
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-[1150px] sm:py-[30px] py-[5px] m-auto ">
        <div className="grid grid-cols-12">
          <div className="sm:col-span-6 md:col-span-6 col-span-12  bg-[#F9F9F9]">
            <div
              style={{
                backgroundImage: "url(/mask1.png)",
                height: "100%",
                width: "100%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="flex justify-center items-center  ">
                <div className="max-w-[316px]  flex justify-center items-center">
                  <p className="lg:text-[64px] md:text-[64px] px-20  text-[27.44px]  my-20 font-bold font-sans ">
                    Get your estimated price
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-6 md:col-span-6 col-span-12  bg-[#05164D] sm:my-0 my-5 py-[30px]">
            <div className="flex justify-center items-center   ">
              <p className="lg:text-[24px] md:text-[20px] sm:text-[16px] text-[16px] text-white font-bold font-sans ">
                Storage capacity(in GB):
              </p>{" "}
            </div>
            <div className="flex justify-center items-center mt-3 ">
              <div className="bg-white max-w-[350px]  flex p-[2px]">
                <div
                  onClick={decrease1}
                  className="max-w-[400px] w-[50px] h-[43px]  bg-[#FFAD00] cursor-pointer"
                >
                  <p className="text-[24px] text-center text-white  font-bold font-sans justify-center item center">
                    -
                  </p>{" "}
                </div>
                <div className="max-w-[150px] w-[100%]  p-[3px]  h-[43px] bg-white">
                  <p className="text-[24px] text-center font-bold font-sans justify-center item center">
                    {counter}
                  </p>{" "}
                </div>
                <div
                  onClick={increase1}
                  className=" max-w-[400px] w-[50px] h-[43px]  bg-[#FFAD00] cursor-pointer"
                >
                  <p className="text-[24px] text-center text-white  font-bold font-sans justify-center item center">
                    +
                  </p>{" "}
                </div>
              </div>
            </div>
            <div className=" flex justify-center items-center ">
              <div className="flex ">
                <p className="lg:text-[99.16px] md:text-[69.16px] sm:text-[49.16px] text-[49.16px] text-white  font-bold font-sans ">
                  {gbValue1.toFixed(2)}
                </p>
              </div>
              <div className="mt-2">
                <p className=" sm:text-[42.97px] text-[24.67px] text-[#FFAD00]   font-bold font-sans  ">
                  €
                </p>
                <p className="sm:text-[39.67px] text-[24.67px] text-white font-medium font-sans  ">
                  / Monthly
                </p>
              </div>
            </div>
            <div className="flex justify-center  items-center  ">
              <p className="sm:text-[16px] text-[12px] text-center text-white font-[400] sm:font-semibold font-sans justify-center item center">
                * Each GB increase above 100gb equals to 0.16€
              </p>{" "}
            </div>
            <div
              onClick={handleModal}
              className="flex justify-center items-center my-5  "
            >
              <div className=" flex bg-[#FFAD00]  space-x-3 justify-between items-center px-5 py-2">
                <button className="  text-[16px] text-white  font-semibold font-sans justify-center item center">
                  PURCHASE NOW
                </button>
                <div>
                  {" "}
                  <img src="/Group.png" alt="image arror" className="p-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center  items-center mt-6">
          <p className="sm:text-[24px] px-5 text-[16px] font-[400]">
            Data-safe will only charge for the capacity used and not for the
            maximum capacity
          </p>
        </div>
      </div>
      {settingState ? (
        <div className="flex justify-center ">
          <div
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            onClick={closeNavbar}
            className="fixed inset-0  opacity-90 z-40 h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>

          <div
            style={{
              backgroundImage: "url(/modalbg.png)",
              backgroundSize: "cover",
            }}
            className="z-50 bg-white fixed inset-0 m-auto h-fit     max-w-[94%]  w-[525px]  pb-10    rounder-sm "
          >
            <div className="h-[88px] px-6 py-7">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="">
              <div className=" text-center pt-7 font-[700] sm:text-[28px] text-[20px]  rounded-xl ">
                Product Explaination
              </div>
              <div className="px-5  py-3 font-[400] sm:text-[22px] text-[14px]   rounded-xl ">
                <p>
                  The amount of data stored differs from person to person.
                  <br /> We all create, use, and store different amounts of data
                  depending on our needs. By answering the following questions,
                  we will get a personalized estimate of the cost to safely and{" "}
                  <span className="font-bold">
                    securely store your data in the cloud.
                  </span>
                </p>
              </div>
              <div className=" flex justify-center mt-5 ">
                <button
                  onClick={OpenSecondModal}
                  className="text-white bg-[#05164D] py-2 px-6 "
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {iconsModal ? (
        <div className="flex justify-center ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40 h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>
          <div
            className="fixed inset-0 m-auto  z-50  max-w-[94%]  w-[525px] sm:h-[428.29px]  h-[100vh]  overflow-auto  rounder-sm "
            style={{
              backgroundImage: "url(/modalbg.png)",
              backgroundSize: "cover",
            }}
          >
            <div className=" h-[88px] px-6 py-7">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className=" mt-4">
              <div className=" text-center sm:py-5 py-3 font-[700] sm:text-[28px] text-[20px]  rounded-xl ">
                Where is your data stored?
              </div>
              <div className="grid grid-cols-12  px-5">
                {Modaldata.map((item, index) => (
                  <div className="sm:col-span-4 col-span-12  my-2 sm:px-[10px] cursor-pointer">
                    <div
                      onClick={() => handleClick(item, index)}
                      className={` ${
                        isDivSelected && whereDataStore === index
                          ? " border-[#FFAD00] border-4 md:block flex md:justify-center bg-[#fffaef] text-white"
                          : "border-[1px] "
                      }`}
                    >
                      <div className=" sm:block flex items-center py-2 ">
                        <img
                          src={item.image}
                          alt="icon"
                          className="h-[80px] w-[80px] mr-6 ml-7 "
                        />
                        <p className="text-center  text-[16px] mt-2 text-[#7A7A7A] font-[600] ">
                          {item.paragraph}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex justify-center mt-5 ">
              <button
                onClick={() => {
                  setSettingState(true);
                  setIconsModal(false);
                  setModals(0);
                }}
                className="text-[#7A7A7A] font-bold py-2 px-6 "
              >
                BACK
              </button>
              <button
                onClick={OpenFormodal}
                className="text-white bg-[#05164D] py-2 px-6 "
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {ModalFor ? (
        <div className="flex justify-center ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40  h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>
          <div
            className="fixed inset-0 m-auto  z-50 h-fit max-w-[94%]  w-[525px] pb-10     rounder-sm "
            style={{
              backgroundImage: "url(/modalbg.png)",
              backgroundSize: "cover",
            }}
          >
            <div className="h-[108px] px-6 py-7">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="">
              <div className=" text-center py-5 font-[700] sm:text-[28px] text-[20px]    rounded-xl ">
                What are these devices for?
              </div>
              <div className="grid grid-cols-12 sm:space-x-5  px-4">
                {Modaldata3.map((obj, index) => (
                  <div
                    key={index}
                    className="sm:col-span-6 justify-center  col-span-12 my-2 sm:px-[10px]"
                    onClick={() => handleDevice(obj, index)}
                  >
                    <div
                      className={`sm:block flex md:justify-center  cursor-pointer sm:px-10 sm:py-5 py-3 px-3 ${
                        whatAreDevices && whatAreDevicesIndex === index
                          ? "border-[#FFAD00] border-4 bg-[#fffaef]"
                          : "border-[1px] "
                      }   cursor-pointer`}
                    >
                      <div className="flex justify-center ">
                        <img
                          src={obj.image}
                          alt="icon"
                          className="h-[80px] w-[80px]"
                        />
                      </div>
                      <p className="text-center mt-5 text-[#7A7A7A] text-[16px] font-[600]">
                        {obj.paragraph}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex justify-center mt-5 ">
              <button
                onClick={() => {
                  setIconsModal(true);
                  setModalFor(false);
                  setModals(1);
                }}
                className="text-[#7A7A7A] font-bold py-2 px-6 "
              >
                BACK
              </button>
              <button
                onClick={OpenDevice}
                className="text-white bg-[#05164D] py-2 px-6 "
              >
                NEXT
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {ModalDevice ? (
        <div className="flex justify-center ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40 h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>
          <div
            className="fixed inset-0 m-auto  z-50 h-fit max-w-[525px]  w-[94%] pb-10    rounder-sm "
            style={{
              backgroundImage: "url(/modalbg.png)",
              backgroundSize: "cover",
            }}
          >
            <div className="h-[108px] px-6 py-7">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="">
              <div className=" text-center px-8 py-7 font-[700]  sm:text-[27px] text-[20px]  rounded-xl ">
                How many gigabytes can you store in this devices?
              </div>
              <div className="flex justify-center items-center ">
                <div className="bg-white max-w-[250px] w-[100%]  flex justify-between  border">
                  <div
                    className="max-w-[400px] w-[60px] h-[60px]  bg-[#FFAD00] cursor-pointer"
                    onClick={decrease}
                  >
                    <p className="text-[32px] text-center text-white  font-bold font-sans justify-center item center">
                      -
                    </p>{" "}
                  </div>
                  <div className=" p-[1px]  h-[60px] bg-white">
                    <p className="text-[21px] mt-[15px]  text-center font-bold font-sans justify-center item center">
                      {count} GB
                    </p>{" "}
                  </div>
                  <div
                    className=" max-w-[400px] w-[60px] h-[60px]  bg-[#FFAD00] cursor-pointer"
                    onClick={increase}
                  >
                    <p className="text-[32px] text-center text-white  font-bold font-sans justify-center item center">
                      +
                    </p>{" "}
                  </div>
                </div>
              </div>
              <div className=" flex justify-center mt-16 ">
                <button
                  onClick={() => {
                    setModalFor(true);
                    setModalDevice(false);
                    setModals(2);
                  }}
                  className="text-[#7A7A7A] font-bold py-2 px-6 "
                >
                  BACK
                </button>
                <button
                  onClick={Opencondition}
                  className="text-white bg-[#05164D] py-2 px-6 "
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {ModalCondition ? (
        <div className="flex justify-center ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40 h-[100vh]"
          ></div>
          <div className="bg-white fixed inset-0 m-auto  z-50  max-w-[840px]  sm:h-[90vh] overflow-auto my-scroller  w-[94%]">
            <div className=" bg-[#05164D] h-[83px] p-5">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className=" sm:px-10 px-5 pb-20">
              <div className=" text-center  py-5 font-[700] sm:text-[33px] text-[14px]   rounded-xl ">
                We’re preparing your personalized quote. Let us know your email
                address, and we’ll send it shortly.
              </div>
              <div className="flex justify-center items-center ">
                <div className="bg-white max-w-[350px]  flex space-x-6 ">
                  <div className=" sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] flex justify-center items-center text-white rounded-full   bg-[#FFAD00]">
                    1
                  </div>
                  <div className=" p-[1px] rounded-full flex justify-center items-center sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] bg-[#EBEBEB]">
                    2
                  </div>
                  <div className="flex justify-center items-center text-center sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] rounded-full   bg-[#EBEBEB]">
                    3
                  </div>
                </div>
              </div>
              <div className="py-1 font-[700] lg:text-[16px] text-[16px]   rounded-xl ">
                Email
              </div>
              <div class="mb-1">
                <input
                  type="email"
                  id="email"
                  value={email}
                  class="bg-gray-50 border border-gray-300 text-[20px] py-2 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="py-1 font-[700] lg:text-[20px] text-[16px]   rounded-xl ">
                Terms and conditions
              </div>
              <div className=" sm:p-5 p-5 bg-[#f9f9f9] font-[400] h-32 overflow-scroll sm:text-[14.87px] text-[16px]  rounded-sm ">
                <p>
                  For the purposes of this contract, the following words shall
                  have the meaning specified below: ANNEX TO THE CONTRACT,
                  refers to the documentation attached to this contract, and
                  which specifies certain aspects of it, having for all purposes
                  the same force to bind the parties as this document. STORAGE,
                  OR BACKUP SERVICE: Custody of the information sent by the
                  client, in remote equipment available to Traffix Marketing
                  online SCP -in order to ensure the maximum preservation of
                  such information-. It includes not only the reception of the
                  information and its custody but also the means to facilitate
                  access by the client, to retrieve the information by computer
                </p>
              </div>
              <div className="p-1 bg-white mt-2 font-[400] sm:text-[14.87px]  text-[14px]  rounded-sm ">
                <p className="text-[#FFAD00]">
                  <span className="border-b-2 border-b-[#FFAD00] font-bold">
                    <Link to="/terms">Terms and conditions</Link>
                  </span>{" "}
                  <span className="border-b-2 border-b-[#FFAD00] font-bold">
                    <Link to="/privacy">Privacy policy</Link>
                  </span>{" "}
                  <span className="border-b-2 border-b-[#FFAD00] font-bold">
                    <Link to="/cookies">Cookie policy</Link>
                  </span>{" "}
                </p>
                <div class="flex items-center mb-4 mt-2">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value={checkBox}
                    checked={checkBox}
                    onChange={(e) => setCheckBox(e.target.checked)}
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    for="default-checkbox"
                    class="ml-4 smLtext-[18.87px] text-[16px] font-medium text-gray-900 dark:text-gray-300"
                  >
                    I read and agree to the terms of service
                  </label>
                </div>
              </div>
              <div className=" flex justify-center mt-3 ">
                <button
                  onClick={() => {
                    setModalDevice(true);
                    setModalCondition(false);
                    setModals(3);
                  }}
                  className="text-[#7A7A7A] font-bold py-2 px-6 "
                >
                  BACK
                </button>
                <button
                  onClick={OpenCapacity}
                  className="text-white bg-[#05164D] py-2 px-6 "
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {ModalCapacity ? (
        <div className="flex justify-center  ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40 h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>
          <div
            className="fixed inset-0 m-auto  z-50 max-w-[525px]  w-[94%] sm:h-[473.29px] h-[641.29px]    rounder-sm "
            // style={{
            //   backgroundImage: "url(/modalbg.png)",
            //   backgroundSize: "cover",
            // }}
          >
            <div className=" bg-[#05164D] h-[83px] p-5">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white cursor-pointer"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className=" bg-white sm:px-10 px-5 py-4">
              <div className=" text-center py-2 font-[700] sm:text-[16px] text-[21px]    rounded-xl ">
                Thank you for answering our questions! Here’s how much your data
                backup will cost if you maximize the capacity of your device:
              </div>
              <div className="flex justify-center items-center ">
                <div className="bg-white max-w-[350px]  flex space-x-6 ">
                  <div className="max-w-[400px] p-[1px] rounded-full sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] bg-[#05164D]">
                    <p className="text-[21px] sm:mt-[5px] mt-[2px]  text-center text-white font-bold font-sans justify-center item center">
                      1
                    </p>{" "}
                  </div>

                  <div className="max-w-[400px] p-[1px] rounded-full sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] bg-[#FFAD00]">
                    <p className="text-[21px] sm:mt-[5px] mt-[2px]  text-center text-white font-bold font-sans justify-center item center">
                      2
                    </p>{" "}
                  </div>
                  <div className=" max-w-[400px] flex justify-center text-center sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] rounded-full bg-[#EBEBEB]">
                    <p className="text-[21px] sm:mt-[5px] mt-[2px] text-center text-[#6B6B6B]  font-bold font-sans justify-center item center">
                      3
                    </p>{" "}
                  </div>
                </div>
              </div>

              <div className="py-1 text-center font-[700] text-[16px]   rounded-xl ">
                Storage capacity: {count}&nbsp; GB
              </div>
              <div className=" flex justify-center items-center ">
                <div className="flex ">
                  <p className="lg:text-[44.16px] md:text-[69.16px] font-[700] sm:text-[49.16px] text-[49.39px] p-1 font-sans ">
                    {gbValue.toFixed(2)}
                  </p>
                </div>
                <div className="">
                  <p className=" sm:text-[24.67px] text-[23px] text-[#FFAD00]   font-bold font-sans  ">
                    €
                  </p>
                  <p className="sm:text-[24.67px] text-[23px]   font-semibold font-sans  ">
                    / Monthly
                  </p>
                </div>
              </div>
              <div className=" text-center  font-semibold text-[16px] italic   rounded-xl ">
                *With our service,
                <span className="font-bold">
                  you only pay for the data you use.
                </span>
                We don’t charge you for the maximum capacity of your device,
                which includes any unused space.
              </div>

              <div className=" flex justify-center mt-4 ">
                <button
                  onClick={() => {
                    setModalCondition(true);
                    setModalCapacity(false);
                    setModals(4);
                  }}
                  className="text-[#7A7A7A] font-bold py-2 px-6 "
                >
                  BACK
                </button>
                <button
                  onClick={OpenCradit}
                  className="text-white bg-[#05164D] py-2 px-6 "
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {ModalCradit ? (
        <div className="flex justify-center ">
          <div
            onClick={closeNavbar}
            style={{
              background:
                " linear-gradient(90deg, rgba(27,26,27,0.4682247899159664) 0%, rgba(27,26,27,0.7483368347338936) 100%)",
            }}
            className="fixed inset-0  opacity-50 z-40 h-[100vh] bg-[rgba(0, 0, 0, 0.6)]"
          ></div>
          <div className="bg-white  fixed inset-0 m-auto  z-50    max-w-[94%]  w-[525px]  sm:h-[575.29px] h-[671.29px]   rounder-sm ">
            <div className=" bg-[#05164D] h-[88px] p-5">
              <div className="flex justify-between items-center ">
                <img
                  src="/Group (3).svg"
                  alt="logo"
                  className="w-[150px] h-auto"
                />
                <svg
                  onClick={closeNavbar}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
            <div className="sm:px-10 px-2">
              <div className=" text-center sm:py-3 py-3 font-[700] sm:text-[16px] text-[21px]   rounded-xl ">
                Are you ready to get started? Just enter your payment details
                below, and we’ll take care of the rest.
              </div>
              <div className="flex justify-center items-center ">
                <div className="bg-white max-w-[350px]  flex space-x-6 ">
                  <div className=" text-white font-bold flex justify-center items-center text-[21px] sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] rounded-full   bg-[#05164D]">
                    1
                  </div>
                  <div className=" text-white font-bold flex justify-center items-center text-[21px] p-[1px] rounded-full sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] bg-[#05164D]">
                    2
                  </div>
                  <div className="  flex justify-center items-center text-[21px] text-center text-white font-bold sm:w-[40px] w-[35px] h-[35px] sm:h-[40px] rounded-full   bg-[#FFAD00]">
                    3
                  </div>
                </div>
              </div>
              <div className="py-3 font-[500] sm:text-[16px] text-[18px]  rounded-xl ">
                Credit card information
              </div>
              <div className="py-3">
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    cartHolderName={cartHolderName}
                    setCardHolderName={setCardHolderName}
                    setCountry={setCountry}
                    country={country}
                    submitModal={submitModal}
                    setModalCapacity={setModalCapacity}
                    setModalCradit={setModalCradit}
                    setModals={setModals}
                    storeData={storeData}
                    storeDevice={storeDevice}
                    email={email}
                    gbValue={gbValue}
                    counter={counter}
                    setSelectedCountry={setSelectedCountry}
                    selectedCountry={selectedCountry}
                  />
                </Elements>
              </div>
              {/* <div class=" relative">
                <input
                  type="email"
                  id="email"
                  class=" border border-gray-300 text-[20px] py-2 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="1234 1234 1234 1234"
                  required
                />
                <div className="absolute top-1 right-2">
                  <img src="image 16.png" alt="image" className="h-[30px]" />
                </div>
              </div> */}
              {/* <div className="flex">
                <input
                  type="email"
                  id="email"
                  class=" border border-gray-300 text-[20px] py-2 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  placeholder="CVC"
                  required
                />
                <input
                  type="email"
                  id="email"
                  class=" border border-gray-300 text-[20px] py-2 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="MM/DD"
                  required
                />
              </div> */}
              {/* <div className="py-3 font-[500] sm:text-[16px] text-[18px]   rounded-xl ">
                Card holder name
              </div> */}
              {/* <div class="">
                <input
                  type="email"
                  id="email"
                  class=" border border-gray-300 text-[20px] py-2 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  required
                />
              </div> */}
              {/* <div className="py-3 font-[500] sm:text-[16px] text-[18px]   rounded-xl ">
                Country
              </div> */}
              {/* <div class="">
                <input
                  type="email"
                  id="email"
                  class="bg-gray- border border-gray-300 text-[20px] py-2 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Crotia"
                  required
                />
              </div> */}

              {/* <div className=" flex justify-center mt-7 ">
                <button
                  onClick={() => {
                    setModalCapacity(true);
                    setModalCradit(false);
                    setModals(5);
                  }}
                  className="text-[#7A7A7A] font-bold py-2 px-6 "
                >
                  BACK
                </button>
                <button
                  onClick={submitModal}
                  className="text-white bg-[#05164D] py-2 px-6 "
                >
                  Subscribe
                </button>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Pricing />
      <Footer />
    </React.Fragment>
  );
};

export default Price;
