import React, { useState, useRef, useEffect } from "react";
import TopHeader from "../../components/topHeader";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Accordion = ({ title, content, index, activeIndex, setActiveIndex }) => {
  const [isOpen, setIsOpen] = useState(false);
  const accordionRef = useRef(null);

  const toggleAccordion = () => {
    setActiveIndex(isOpen ? null : index);
  };

  useEffect(() => {
    const accordionElement = accordionRef.current;
    accordionElement.style.maxHeight = isOpen
      ? `${accordionElement.scrollHeight}px`
      : "0px";
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(index === activeIndex);
  }, [index, activeIndex]);

  return (
    <div className="bg-[#f9f9f9] rounded-md mt-5">
      <button
        className="flex items-center w-full p-4 focus:outline-none"
        onClick={toggleAccordion}
      >
        {" "}
        <div className="transform transition-transform duration-300">
          {isOpen ? (
            <AiOutlineMinus className="text-[#FFAD00] w-8 h-8" />
          ) : (
            <AiOutlinePlus className="text-[#FFAD00] w-8 h-8" />
          )}
        </div>
        <h3 className="sm:text-[24px] text-[12px] ml-3">{title}</h3>
      </button>
      <div
        ref={accordionRef}
        className={`overflow-hidden transition-all duration-300`}
      >
        <div className="p-4 sm:ml-10">
          <p className="sm:text-[24px] text-[12px]">{content}</p>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <React.Fragment>
      <Header />
      <TopHeader text="FAQs" />
      <div className="py-[80px] lg:px-[250px] md:px-[47px] px-6">
        <h1 className="font-[700] sm:text-[32px] text-[20px] text-center">
          Frequently Asked Questions
        </h1>
        <div>
          <Accordion
            index={1}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Does datasafe work in all devices?"
            content="Frequently Asked Questions"
          />
          <Accordion
            index={2}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="What happens to my data if I unsubscribe from the service?"
            content="All of your data will be deleted at the end of your subscription."
          />

          <Accordion
            index={4}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I do my backups locally?"
            content="Yes, you can define a local path where you will keep an exact copy of what you have in the cloud."
          />
          <Accordion
            index={5}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I deactivate the Cloud backups?"
            content="No, the cloud backup is the main backup."
          />
          <Accordion
            index={6}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="For how long do you keep my backups?"
            content="Initially you data will be kept for 5 years. If you need more time for legal reason, please contact us."
          />
          <Accordion
            index={7}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I increase the time my backups are saved?"
            content="Yes, please contact us with your needs."
          />
          <Accordion
            index={8}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I increase the size of my backups?"
            content="Yes, at any time."
          />
          <Accordion
            index={9}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I put a GB limit so I do not spend extra?"
            content="Yes, you can limit the amount of GB by the predefined amounts (100-200-300-400-500..)"
          />
          <Accordion
            index={10}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Is the software available in english or other languages?"
            content="You can change the language after downloading our Datasafe backup manager."
          />
          <Accordion
            index={11}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="How often will I pay?"
            content="Monthly basis."
          />
          <Accordion
            index={12}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            title="Can I get an invoice?"
            content="Yes, simply add the company details through your personal dashboard and an invoice will be generated at theend of each billing cycle.
"
          />
          {/* Add more accordions as needed */}
        </div>
      </div>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default FAQ;
