import React from "react";
import Diclaimer from "../../components/disclaimer";
import TopHeader from "../../components/topHeader";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Disclaimer = () => {
  return (
    <React.Fragment>
      <Header />
      <TopHeader text="Disclaimer" />
      <Diclaimer />
      <Footer />
    </React.Fragment>
  );
};

export default Disclaimer;
