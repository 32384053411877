import React from "react";
import Conditions from "../../components/termsAndConditions";
import TopHeader from "../../components/topHeader";
import Footer from "../../components/footer";
import Header from "../../components/header";

const Terms = () => {
  return (
    <React.Fragment>
      <Header />
      <TopHeader text="Terms and conditions of use" />
      <Conditions />
      <Footer />
    </React.Fragment>
  );
};

export default Terms;
